
import { defineAsyncComponent, defineComponent, ref } from "vue";
import api from '@/api/spaceMountainAPI';
import {useUserStore} from "@/stores/user";
import {storeToRefs} from "pinia";

export default defineComponent({
  name: "ChangePassword",
  components: {
    Modale: defineAsyncComponent(() => import('@/modales/Modale.vue')),
    Input: defineAsyncComponent(() => import('@/components/Input.vue')),
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    Loader: defineAsyncComponent(() => import('@/components/Loader.vue')),
  },
  props: {
    isOpen: { type: Boolean, default: 'false' },
  },
  setup(props, { emit }) {
    //STORE user
    const userStore = useUserStore();
    const { userLoader } = storeToRefs(userStore);

    //DATA
    const password = ref('');

    const changePassword = () => {
      userStore.goLoader();
      api.changePassword(password.value).then(() => {
        emit('change-password');
        userStore.stopLoader();
      });
    }

    return { password, changePassword, userLoader };
  },
});
