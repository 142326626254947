
import { defineAsyncComponent, defineComponent, ref } from "vue";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";

export default defineComponent({
  name: "ChangeUserInfos",
  components: {
    Modale: defineAsyncComponent(() => import('@/modales/Modale.vue')),
    Input: defineAsyncComponent(() => import('@/components/Input.vue')),
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    Loader: defineAsyncComponent(() => import('@/components/Loader.vue')),
  },
  props: {
    isOpen: { type: Boolean, default: 'false' },
  },
  setup(props, { emit }) {
    //STORE user
    const userStore = useUserStore();
    const { user, userLoader } = storeToRefs(userStore);

    //DATA
    const firstname = ref(user.value.firstname);
    const lastname = ref(user.value.lastname);

    const changeInfos = () => {
      userStore.updateAdmin(firstname.value, lastname.value).then(() => {
        emit('change-userinfos');
      });
    }

    return { firstname, lastname, changeInfos, userLoader, user };
  },
});
